<template>
  <div id="main-content">
    <a-spin :spinning="spinning">
      <a-row :gutter="16">
        <a-col :span="20">
          <div id="map">
          </div>
        </a-col>
        <a-col :span="4">
          <p>当前：{{province.name}}</p>
          <a-table
            size="small"
            :data-source="provinceSpecies"
            :columns="columns"
            :pagination="pagination"
            :row-key="record => record.slug"
            @change="onChange"
          >
            <template slot="name" slot-scope="text, record">
              <a :href="`/species/${record.slug}`" target="_blank">
                {{text}}
              </a>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
// import AMapLoader from '@amap/amap-jsapi-loader';
import {list as listProvinceStats} from '@/services/provinceStats';
import {list as listProvinceSpecies} from '@/services/provinceSpecies';
export default {
  name: 'Content',
  data() {
    return {
      spinning: true,
      map: null,
      type: 1,
      province: {
        'adcode': '440000',
        'name': '广东省',
        'longitude': 113.266887,
        'latitude': 23.133306
      },
      provinceStats: [],
      provinceSpecies: [],
      columns: [{
        title: '物种名称',
        dataIndex: 'name',
        align: 'center',
        scopedSlots: {customRender: 'name'}
      }],
      pagination: {
        current: 1,
        pageSize: 15
      },
    };
  },
  watch: {
    province() {
      this.pagination = {
        current: 1,
        pageSize: 15
      };
      this.fetchProvinceSpecies();
    }
  },
  methods: {
    fetchProvinceStats() {
      listProvinceStats({
        type: this.type
      }).then(({data}) => {
        this.provinceStats = data.data;
      });
    },
    fetchProvinceSpecies() {
      listProvinceSpecies({
        province: this.province.adcode,
        type: this.type,
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      }).then(({data}) => {
        const pager = { ...this.pagination };
        pager.total = data.data.count;
        this.pagination = pager;
        this.provinceSpecies = data.data.results.map(
            provinceSpecies => provinceSpecies.species
        );
      });
    },
    initMap() {
      let that = this
      //创建地图
      var map = new window.AMap.Map('map', {
          zoom: 5
      });
      //just some colors
      var colors = [
          "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00",
          "#b82e2e", "#316395", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707",
          "#651067", "#329262", "#5574a6", "#3b3eac"
      ];

      window.AMapUI.load(['ui/geo/DistrictExplorer', 'lib/$'], function(DistrictExplorer, $) {

          //创建一个实例
          var districtExplorer = window.districtExplorer = new DistrictExplorer({
              eventSupport: true, //打开事件支持
              map: map
          });

          //当前聚焦的区域
          var currentAreaNode = null;

          //鼠标hover提示内容
          var $tipMarkerContent = $('<div class="tipMarker top"></div>');

          var tipMarker = new window.AMap.Marker({
              content: $tipMarkerContent.get(0),
              offset: new window.AMap.Pixel(0, 0),
              bubble: true
          });

          //根据Hover状态设置相关样式
          function toggleHoverFeature(feature, isHover, position) {
              tipMarker.setMap(isHover ? map : null);

              if (!feature) {
                  return;
              }
              var props = feature.properties;
              props.count = 0
              let result = that.provinceStats.filter(item => {
                return item.province.adcode == props.adcode
              })
              if(result && result.length) {
                props.count = result[0].count
              }

              if (isHover) {

                  //更新提示内容
                  $tipMarkerContent.html(props.name + ': ' + props.count);
                  //更新位置
                  tipMarker.setPosition(position || props.center);
              }


              //更新相关多边形的样式
              var polys = districtExplorer.findFeaturePolygonsByAdcode(props.adcode);
              for (var i = 0, len = polys.length; i < len; i++) {

                  polys[i].setOptions({
                      fillOpacity: isHover ? 0.5 : 0.2
                  });
              }
          }

          //监听feature的hover事件
          districtExplorer.on('featureMouseout featureMouseover', function(e, feature) {
              toggleHoverFeature(feature, e.type === 'featureMouseover',
                  e.originalEvent ? e.originalEvent.lnglat : null);
          });

          //监听鼠标在feature上滑动
          districtExplorer.on('featureMousemove', function(e) {
              //更新提示位置
              tipMarker.setPosition(e.originalEvent.lnglat);
          });
          //feature被点击
          districtExplorer.on('featureClick', function(e, feature) {

              var props = feature.properties;
              let result = that.provinceStats.filter(item => {
                return item.province.adcode == props.adcode
              })
              if(result && result.length) {
                // props.count = result[0].count
                that.province = result[0].province;
              }
              //如果存在子节点
              // if (props.childrenNum > 0) {
                  //切换聚焦区域
                  // switch2AreaNode(props.adcode);
              // }
          });
          //绘制某个区域的边界
          function renderAreaPolygons(areaNode) {
              //更新地图视野
              map.setBounds(areaNode.getBounds(), null, null, true);

              //清除已有的绘制内容
              districtExplorer.clearFeaturePolygons();

              //绘制子区域
              districtExplorer.renderSubFeatures(areaNode, function(feature, i) {

                  var fillColor = colors[i % colors.length];
                  var strokeColor = colors[colors.length - 1 - i % colors.length];

                  return {
                      cursor: 'default',
                      bubble: true,
                      strokeColor: strokeColor, //线颜色
                      strokeOpacity: 1, //线透明度
                      strokeWeight: 1, //线宽
                      fillColor: fillColor, //填充色
                      fillOpacity: 0.35, //填充透明度
                  };
              });

              //绘制父区域
              districtExplorer.renderParentFeature(areaNode, {
                  cursor: 'default',
                  bubble: true,
                  strokeColor: 'black', //线颜色
                  strokeOpacity: 1, //线透明度
                  strokeWeight: 1, //线宽
                  fillColor: areaNode.getSubFeatures().length ? null : colors[0], //填充色
                  fillOpacity: 0.35, //填充透明度
              });
          }
          //切换区域
          function switch2AreaNode(adcode, callback) {

              if (currentAreaNode && ('' + currentAreaNode.getAdcode() === '' + adcode)) {
                  return;
              }

              loadAreaNode(adcode, function(error, areaNode) {

                  if (error) {

                      if (callback) {
                          callback(error);
                      }

                      return;
                  }

                  currentAreaNode = window.currentAreaNode = areaNode;

                  //设置当前使用的定位用节点
                  districtExplorer.setAreaNodesForLocating([currentAreaNode]);

                  renderAreaPolygons(areaNode);
                  /* districtExplorer.loadAreaNode(that.province.adcode, function(error, areaNode) {

                      if (error) {

                          if (callback) {
                              callback(error);
                          }

                          console.error(error);

                          return;
                      }

                      renderAreaPolygons(areaNode);

                      if (callback) {
                          callback(null, areaNode);
                      }
                  }); */

                  if (callback) {
                      callback(null, areaNode);
                  }
              });
              that.spinning = false
          }

          //加载区域
          function loadAreaNode(adcode, callback) {

              districtExplorer.loadAreaNode(adcode, function(error, areaNode) {

                  if (error) {

                      if (callback) {
                          callback(error);
                      }

                      console.error(error);

                      return;
                  }

                  if (callback) {
                      callback(null, areaNode);
                  }
              });
          }
          //全国
          switch2AreaNode(100000); 
      });
      
    },
    /* initMap() {
      AMapLoader.load({
        key: '8b060e2e24cad19aa42184069af5394a',
        version: '2.0',
        AMapUI: {
          version: '1.1',
          plugins: ['geo/DistrictExplorer']
        }
      }).then(() => {
        this.map = new window.AMap.Map('map', {
          zoom: 5,
          center: [108, 34],
          resizeEnable: true,
        });
        this.provinceStats.forEach(provinceStat => {
          let text = new window.AMap.Text({
            text: provinceStat.count,
            anchor: 'center',
            cursor: 'pointer',
            style: {
              'width': '3rem',
              'height': '2rem',
              'text-align': 'center',
              'color': 'white',
              'background-color': '#678CBC',
            },
            position: [
              provinceStat.province.longitude,
              provinceStat.province.latitude
            ]
          });
          let that = this;
          text.on('click', function () {
            that.province = provinceStat.province;
          });
          text.setMap(this.map);
        });
        this.spinning = false;
      });
    }, */
    onChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetchProvinceSpecies();
    }
  },
  mounted() {
    this.initMap();
    this.fetchProvinceStats();
    this.fetchProvinceSpecies();
  }
}
</script>

<style lang="stylus" scoped>
#main-content
  padding-top: 2rem

  #map
    height: 780px
</style>
<style lang="stylus">
.tipMarker 
  color: #555;
  background-color: rgba(255,254,239,0.8);
  border: 1px solid #7E7E7E;
  padding: 2px 6px;
  font-size: 12px;
  white-space: nowrap;
  display: inline-block;
.tipMarker.top 
    transform: translate(-50%,-110%);

</style>
